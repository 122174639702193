/* .App {
  text-align: center;
} */
/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
} */
/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
/*
.App-link {
  color: #61dafb;
} */

* {
  box-sizing: border-box;
}

body {
  font-family: Sofia Pro, Arial, Helvetica, sans-serif;
  margin: 0 0 80px 0;background: #fff;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stage {
  display: none;
}

.stage.current {
  display: block;
}

dialog {
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
  height: auto;
  background-color: #ddde;background: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  dialog: nonw;
}

dialog.hidden {
  display: none;
}

dialog.visible {
  display: flex;
}

h1.getQuote, h1.shareEstimate {
  color: #222;
  font-size: 50px;
  text-align: center;
}

.quoteSummary, .shareSummary {
  color: #757575;
  font-weight: 700;
  margin: 0 0 20px;
  text-align: center;
  text-transform: uppercase;
}

.getQuote input[type=text], input[type=email], select, .shareEstimate input[type=text], .shareEstimate textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.getQuote input[type=submit], .shareEstimate input[type=submit] {
  width: 100%;
  background-color: #5ec579;
  color: #fff;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.getQuote input[type=submit]:hover, .shareEstimate input[type=submit]:hover {
  background-color: #63d180;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.close {
  color: #b0b0b0;
  cursor: pointer;
  font-size: 20px;
  position: fixed;
  right: 20px;
  top: 20px;
}

 @media print {
   dialog {
     display: none;
   }
 }

 header {
   background-color: #fff;
   border-bottom: 1px solid #f1f1f1;
   font-size: 15px;
   height: 89px;
   padding: 0 20px 0 0;
   text-align: center;
 }

 .est_logo {
   float: left;
   padding: 30px 40px;
 }

 .est_logo img{
   float: left;
   height: 28px;
 }

 .signin-button, #appleid-signin { /* these are required to have these values by Apple */
    width: 210px;
    height: 40px;
 }

 ul {
   list-style-type: none;
   margin: 0;
   padding: 0;
   overflow: hidden;
 }

 li {

 }

 li a {
   display: block;
   padding: 30px 0 26px;
   background-color: #fff;
   border-bottom: 4px solid #fff;
   text-decoration: none;
   color: #b0b0b0;
   line-height: 28px;
 }

 li a:hover {
   cursor: pointer;
 }

 li a:active {
   color: #b0b0b0;
 }

 .active {
   border-bottom: 4px solid #5ec579;
   color: #222;
 }

    .active:hover {

    }

.visited {
  color: #222;
}

.number {

}
 /* .active:hover {
   opacity: 100%;
 } */

 #nav {
         text-align: justify;
         min-width: 500px;
     }
     #nav:after {
         content: '';
         display: inline-block;
         width: 100%;
     }
     #nav li {
         display: inline-block;width:18%;text-align:center;
     }

 .main {

 }

 .questions {
   bottom: 100px;
   left: 0;
   overflow-y: scroll;
   padding: 50px 40px 40px 40px;
   position: fixed;
   right: 0;
   top: 89px;
 }

   .question {
     color: #222;
     font-size: 24px;
     font-weight: 700;
     padding: 0 0 30px 0;
     text-align: center;
     text-transform: capitalize;
   }

   .instructions {
     color: #222;
     font-size: 14px;
     margin: 0 0 30px;
     text-align: center;
   }

   .answers {
     text-align: center;
   }

     .pill {
       display: inline-block;border: 1px solid #d0d2d3;border-radius: 3px;
       padding: 20px; margin: 0 5px 10px 5px;
     }

      .pill:hover {
        cursor: pointer;
      }

     .pill.selected {
       background: #edeeee;
     }

       .pill .icon {
         height: 100px;
         width: 100px;
         margin: 0 0 10px 0;
       }

.pill:hover .icon {

}

.pill:active .icon {

}

.pill.Consumer .icon {
 background-image: url("/consumer_double.png");
 background-size: 200px;background-position: 200px;
}

.pill.Consumer.selected .icon, .pill.Consumer .icon:hover {
  background-image: url("/consumer_double.png");
  background-size: 200px;background-position: right 200px;
}

.pill.Automotive .icon {
  background-image: url("/automotive_double.png");
  background-size: 200px;background-position: 200px;
}

.pill.Automotive.selected .icon, .pill.Automotive .icon:hover {
  background-image: url("/automotive_double.png");
  background-size: 200px;background-position: right 200px;
}

.pill.Industrial .icon {
  background-image: url("/industrial_double.png");
  background-size: 200px;background-position: 200px;
}

.pill.Industrial.selected .icon, .pill.Industrial .icon:hover {
  background-image: url("/industrial_double.png");
  background-size: 200px;background-position: right 200px;
}

.pill.Medical .icon {
  background-image: url("/medical_double.png");
  background-size: 200px;background-position: 200px;
}

.pill.Medical.selected .icon, .pill.Medical .icon:hover {
  background-image: url("/medical_double.png");
  background-size: 200px;background-position: right 200px;
}

.pill.Energy .icon {
  background-image: url("/energy_double.png");
  background-size: 200px;background-position: 200px;
}

.pill.Energy.selected .icon, .pill.Energy .icon:hover {
  background-image: url("/energy_double.png");
  background-size: 200px;background-position: right 200px;
}

.pill.Aerospace .icon {
  background-image: url("/aerospace_double.png");
  background-size: 200px;background-position: 200px;
}

.pill.Aerospace.selected .icon, .pill.Aerospace .icon:hover {
  background-image: url("/aerospace_double.png");
  background-size: 200px;background-position: right 200px;
}

.pill.I_have_an_idea .icon {
  background-image: url("/idea_double.png");
  background-size: 200px;background-position: 200px;
}

.pill.I_have_an_idea.selected .icon, .pill.I_have_an_idea .icon:hover {
  background-image: url("/idea_double.png");
  background-size: 200px;background-position: right 200px;
}

.pill.New_Design .icon {
  background-image: url("/design_double.png");
  background-size: 200px;background-position: 200px;
}

.pill.New_Design.selected .icon, .pill.New_Design .icon:hover {
  background-image: url("/design_double.png");
  background-size: 200px;background-position: right 200px;
}

.pill.Refine_Project .icon {
  background-image: url("/refine_double.png");
  background-size: 200px;background-position: 200px;
}

.pill.Refine_Project.selected .icon, .pill.Refine_Project .icon:hover {
  background-image: url("/refine_double.png");
  background-size: 200px;background-position: right 200px;
}

.pill.Simulation .icon {
  background-image: url("/simulation_double.png");
  background-size: 200px;background-position: 200px;
}

.pill.Simulation.selected .icon, .pill.Simulation .icon:hover {
  background-image: url("/simulation_double.png");
  background-size: 200px;background-position: right 200px;
}

.pill.Manufacturing .icon {
  background-image: url("/manufacturing_double.png");
  background-size: 200px;background-position: 200px;
}

.pill.Manufacturing.selected .icon, .pill.Manufacturing .icon:hover {
  background-image: url("/manufacturing_double.png");
  background-size: 200px;background-position: right 200px;
}

         .pill.selected .icon {

         }

       .pill .answer {
         color: #222;font-weight: 700;
         font-size: 14px;
       }

         .pill.selected .answer {
           color: #222;
         }

      .answer_summary {
        background: #edeeee;background: none;
        border-radius: 3px;
        color: #757575;
        font-size: 13px;
        line-height: 24px;
        margin: 5px auto 15px auto;width: 750px;
        padding: 10px 20px;
        text-align: center;
      }

   .categories {
     text-align: center;
     margin: 0 0 20px 0;
   }

   .items .selected {
     font-weight: bold;
   }

     .option {
       border: 1px solid #d0d2d3;font-weight: bold;
       border-radius: 3px;
       color: #222;
       display: inline-block;
       font-size: 14px;
       margin: 0 5px 0 5px;
       padding: 8px 16px;
     }

      .option:hover {
        background: #f7f8fa;
        cursor: pointer;
      }

     .option.selected {
       background: #edeeee;
       border: 1px solid #d0d2d3;
       color: #222;
     }

   .similar {
     font-size: 13px;
     margin: 0 0 30px 0;
     text-align: center;
   }

     .similar a {
       color: #16cabb;
       text-decoration: none;
     }

       .similar a:hover {
         cursor: pointer;
         opacity: 80%;
       }
.items {
  margin: 0 0 40px;
}
   .item  {
     border: 1px solid #d0d2d3;text-transform:capitalize;font-weight: 700;
     border-radius: 3px;
     color: #222;
     font-size: 14px;
     padding: 15px 20px;
     margin: 0 auto 10px auto;
     width: 640px;
   }

    .item .subtitle {color: #757575;font-weight: 300; text-transform:none;
      font-size: 12px;
      margin: 3px 0 0 0;
    }

      .item.selected .subtitle {

      }

    .item:hover {
      background: #f7f8fa;
      cursor: pointer;
    }

      .items .item.selected {
        background: #edeeee;
        font-weight: 700;
      }

      .items .item.selected:hover {
        background: #edeeee;
      }

      .items .item .range{
        color: #757575;
        float: right;
        font-weight: 300;
      }

        .items .item.selected .range{

        }

  .slider_container {
    text-align: center;
  }

  .slider {
     -webkit-appearance: none;text-align: center;
     width: 500px;
     height: 3px;
     background: #d0d2d3;
     border-radius: 3px;
     outline: none;
     opacity: 0.7;margin: 20px 0 60px 0;
     -webkit-transition: .2s;
     transition: opacity .2s;
   }

    .slider:hover {
      opacity: 1;
    }

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 130px;
  cursor: pointer;

  background-image: url("/speed.png");
  background-size: 50px;background-position: center;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #5ec579;
  cursor: pointer;
}

ul.range-labels li {
  color: #ccc;width: 150px;margin: 0 0 30px;border: none;padding: 0;font-size: 15px;
}

ul.range-labels li:hover {
  background: #fff;color: #5ec579;
}

ul.range-labels li.selected {
  background: #fff;color: #222;border: none;
}

.subtitle {
  color: #757575;
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 30px;
}

   .next {
     text-align: center;
   }

     .next a {
       background: #5ec579;
       border-radius: 3px;
       color: #dbfce4;
       display: inline-block;
       font-size: 18px;
       padding: 14px 38px;
       text-decoration: none;
     }

       .next a:hover {
         background: #63d180;
         cursor: pointer;
       }

       .next a:active {
         background: #5ec579;
       }

     .next a .white {
       color: #fff;
       display: inline-block;
       font-weight: 700;
       text-transform: uppercase;
     }

      .finished {
        text-align: center;
      }

        .finished a {
          background: #5ec579;
          border-radius: 3px;
          color: #dbfce4;
          display: inline-block;
          font-size: 18px;
          padding: 14px 38px;
          text-decoration: none;
        }

          .finished a:hover {
            background: #63d180;
            cursor: pointer;
          }

          .finished a:active {
            background: #5ec579;
          }

        .finished a .white {
          color: #fff;
          display: inline-block;
          font-weight: 700;
          text-transform: uppercase;
        }
 .summary {
   bottom: 90px;
   display: none;
   left: 66.66%;
   padding: 40px;
   position: fixed;
   right: 0;
   top: 89px;
 }

   .summary .top {
     color: #757575;
     font-size: 20px;
     font-weight: 700;
     padding: 0 0 20px 0;
   }

   .summary h2 {
     color: #b0b0b0;
     font-size: 16px;
     font-weight: 300;
     padding: 0 0 5px 0;
     text-transform: uppercase;
   }

   .summary .summary_description {
     border-left: 2px solid #f1f1f1;
     color: #b0b0b0;
     font-size: 13px;
     font-weight: 300;
     line-height: 18px;
     padding: 0 0 5px 20px;
   }

 /* Container for flexboxes */
 .row {
   display: -webkit-flex;
   display: flex;
 }

 /* Create three unequal columns that sits next to each other */
 .column {

 }

 /* Left column */
 .column.left {
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
    word-break: break-all;
 }

 /* Right column */
 .column.right {background: #fff;padding: 60px;width:400px;border-left:1px solid #f1f1f1;position:fixed;top:89px;right:0;bottom:50px;
   -webkit-flex: 1;
   -ms-flex: 1;
   flex: 1;
   word-break: break-all;
 }

 /* Style the footer */
 footer {
   background-color: #222;
   color: #fff;
   padding: 10px;
   text-align: center;
   position:fixed;
   bottom:0;
   width:100%;
 }

 footer .title {
   font-size: 24px;
   font-weight: bold;display: block;width: 33.33%;float: left;line-height: 80px;
 }

 footer .estimate {
   display: block;
   float: left;
   width: 33.33%;
 }

   footer .estimate .cost {
     font-size: 40px;
     font-weight: 700;
     margin: 10px 0 0 0;
   }

   footer .estimate .currency {
     font-size: 16px;
   }

 footer .quote {
   display: block;
   float: left;
   width: 33.33%;
 }

   footer .quote .get_quote {
     display: inline-block;
     margin: 10px 2% 10px 0;
     width: 42.5%;
   }

     footer .quote .get_quote a {
       background: #16cabb;
       border-radius: 3px;
       color: #fff;
       display: block;
       font-size: 15px;
       font-weight: 700;
       padding: 11px 0;
       text-decoration: none;
       text-transform: uppercase;
       width: 100%;
     }

       footer .quote .get_quote a:hover {
         background: #51a69d;
       }

       footer .quote .get_quote a:active {
         background: #16cabb;
       }

   footer .quote .save {
     display: inline-block;
     margin: 10px 0 10px 2%;
     width: 42.5%;
   }

     footer .quote .save a {
       border: 1px solid #757575;
       border-radius: 3px;
       color: #fff;
       display: block;
       font-size: 15px;
       font-weight: 700;
       padding: 10px 0;
       text-decoration: none;
       width: 100%;
     }

       footer .quote .save a:hover {
         border: 1px solid #656565;
       }

       footer .quote .save a:active {
         background: #757575;
         border: 2px solid #757575;
       }

   footer .quote .disclaimer {
     color: #757575;
     font-size: 10px;
   }

 @media (max-width: 1000px) {
   header {
     font-size: .8em;
   }

   .pill {
     padding: 10px;
   }

   .item  {
     padding: 15px 20px;
     margin: 0 0 10px 0;
     width: 100%;
   }
 }

 /* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
 @media (max-width: 850px) {
body {
   margin: 0 0 240px 0;background: #fff;
   }

   header {
     background-color: #fff;
     border-bottom: 1px solid #f1f1f1;
     height: 64px;
     padding: 0;
     text-align: center;
     font-size: 0;
   }

   .stage {
     display: block;
   }

   .est_logo {
     padding: 20px 20px;
     float: none;
   }

   .est_logo img{
     float: none;
     height: 24px;
   }

   ul#nav {
     display: none;
   }

   .main {
   }

   .instructions {
     font-size: 13px;
     margin: 5px 5% 5px 5%;
     width: 90%
   }

     .questions {
       border-right: none;
       overflow-y: inherit;
       padding: 40px 0px 0px 0px;
       position: relative;
       top: 0px;
     }

      .question {
       font-size: 21px;
       padding: 0 0 10px 0;

      }

     .answers {
       padding: 0 20px;
     }

       .pill {
         padding: 10px 0px;border: none;margin: 0;
       }

        .pill .icon {
          height: 100px;
          width: 100px;
          margin: 0 0 0px 0;
        }

        .pill .answer {
          font-size: 12px;
        }

        .pill.selected {
          background: none;
        }

       .answer_summary {
         width: 100%;font-size: 12px;line-height: 18px;background: none;
       }

       .item  {font-size: 13px;
         padding: 15px;
         margin: 0 5% 5px 5%;
         width: 90%;
       }

       .option {
         font-size: 13px;
         margin: 0 2.5px 5px 2.5px;
       }

       .slider {
          margin: 20px 10% 50px;
          width: 80%;
        }

        ul.range-labels li.selected {
          background: none;
          font-size: 17px;
          margin: 0 0 30px;
        }

        .subtitle {
          color: #757575;
          font-size: 12px;
          line-height: 18px;
          margin: 0 0 30px;
        }

      .next {
        display: none;
      }

      .finished a {display: none;
        width: 60%;height: 80px;line-height: 50px;font-size: 20px;

      }

     .summary {
       display: none;
     }

    .slider_container .option:not(.selected) {
      display: none;
    }


   .row {
     -webkit-flex-direction: column;
     flex-direction: column;
   }

   .column.right {
   }

   footer .title {
     display: none;
  }

  footer .estimate {
    display: block;
    width: 100%;
    float: left;
  }

   footer .estimate .cost {
     font-size: 35px;
     margin: 0;display: inline;
   }

   footer .estimate .currency {
     font-size: 15px;display: inline;margin: 0 0 0 3px;
   }

  footer .quote {
    display: block;
    float: left;
    font-size: 10px;
    line-height: 30px;
    width: 100%;
  }
 }
